const createElement = str => {
  const el = document.createElement('div');
  el.innerHTML = str;
  return el.firstElementChild;
};

function updateTimer() {
  var container = document.getElementById('timer-container');
  let datetime = container.getAttribute("datetime");
	let future = new Date(datetime);
  let now = new Date();
  let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  let diff = future - utc;

  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  let hours = Math.floor(diff / (1000 * 60 * 60));
  let mins = Math.floor(diff / (1000 * 60));
  let secs = Math.floor(diff / 1000);

  let d = days;
  let h = hours - days * 24;
  let m = mins - hours * 60;
  let s = secs - mins * 60;

  const el = createElement(
    `<section class="lesson-timer">
      <div class="container">
        <div id="timer" class="timer">
        </div>
      </div>
    </div>`
  );

  container.innerHTML = '';
  container.appendChild(el);

  var element = document.createElement('timer');;
  let html = '';
  if (window.signedIn) {
    html = "<p>Is <span>unlocked</span> for:</p>";
  } else {
    html = "<p>Can be <span>unlocked</span> with a free account for:</p>";
  }

  if (days > 0) {
    html += '<div>' + d + ' <span>Days</span></div>';
  }
  if (hours > 0) {
    html += '<div>' + h + ' <span>Hours</span></div>';
  }
  if (mins > 0) {
    html += '<div>' + m + ' <span>Minutes</span></div>';
  }
  if (secs > 0) {
    html += '<div>' + s + ' <span>Seconds</span></div>';
  }

  if (days <= 0 && hours <= 0 && mins <= 0 && secs <=0) {
    html += '<div class="promo-ended">The promo has ended</div>';
    element.innerHTML = html;
    return
  }

  element.innerHTML = html;

  var timer = document.getElementById('timer')
  timer.innerHTML = '';
  timer.appendChild(element);

  window.setTimeout(function() {
		updateTimer();
	}, 1000);
}

document.addEventListener('turbolinks:load', () => {
  if ($('#timer-container').length > 0) {
    updateTimer();
  }
});
