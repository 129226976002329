import { Controller } from "stimulus"
import Sortable from "sortablejs"

require("jquery")

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      handle: '.handle',
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let data = new FormData()
    // data.append("position", event.newIndex + 1)

    var sort_items = [];
    $('.sort-item').each(function(i) {
      var item = {}
      item['record_id'] = $(this).data('record_id')
      item['position'] = i + 1
      sort_items.push(item)
    });
    sort_items = JSON.stringify(sort_items)
    data.append("sort_items", sort_items)

    // common to both courses and lessons controller
    let dataset = event.item.dataset
    let url = this.data.get("url").replace(":id", dataset.id)

    // lessons controller only
    if (dataset.courseId) {
      url = url.replace(":course_id", dataset.courseId)
    }

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data,
      success: this.refreshNumbers.bind(this)
    })
  }

  refreshNumbers(event) {
    var i = 0
    $('.number').each(function(i, el) {
      $(el).html(i+1);
    });
  }
}
