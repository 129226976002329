function updateHtml() {
  let data = $('#locked-content').data();
  let html =
    `<img src="${data['image']}" />
     <p class="mb-0">Locked content</p>`;

  // using data['countdown'] since data-show-countdown => data['showCountdown']
  if (data['access'] == 'free' || data['countdown']) {
    html += '<a class="btn btn-base mt-3" href="/users/sign_up">Sign up for a free account for access</a>';
  } else {
    html += '<a class="btn btn-base mt-3" href="/pricing">Subscribe to a plan for access</a>';
  }

  $('#locked-content').html(html);
}

document.addEventListener('turbolinks:load', () => {
  if ($('#locked-content').length > 0) {
    updateHtml();
  }
});
