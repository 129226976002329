import { Controller } from "stimulus"

require("jquery")

export default class extends Controller {
  static targets = [ "list", "caretup", "caretdown" ]

  connect() {
    var expand = $(this.listTarget).data('expand')
    if (expand) {
      this.toggle()
    }
  }

  toggle(e) {
    this.listTarget.classList.toggle("hideme");
    this.caretupTarget.classList.toggle("hideme");
    this.caretdownTarget.classList.toggle("hideme");
    if (e) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

  check(e) {
    if (!window.signedIn) {
      $('#trackingModal').modal('show')
      e.preventDefault()
      return false
    }

    var target = $(e.target);
    var lessonUid = target.attr("id")
    if (target.attr("checked")) {
      target.removeAttr("checked")
      this.completion(lessonUid, "DELETE");
    } else {
      target.checked = "1"
      this.completion(lessonUid, "POST");
    }
  }

  // content locked and completions modals and handling
  completion(lessonUid, method) {
    var url = "/completions"
    if (method == "DELETE") {
      url = url + "/" + lessonUid
    }
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ uid: lessonUid })
    });
  }
}
