// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery");
require("select2");
require("bootstrap");
import "@fortawesome/fontawesome-free/js/all";

window.Rails = Rails

// causes funny redirection issue with clicking on signup from pricing page
// but commenting out breaks signout link js
Rails.start()
Turbolinks.start()
ActiveStorage.start()

// requiring so sign in and out top menu is not broken.
// Using <%= javascript_pack_tag "..." %> in view and requiring on each page seems to break it
// lessons#show page
require("./lesson/modals");
require("./lesson/select2");
require("./lesson/timer");
require("./lesson/locked");

import "controllers"

document.addEventListener('turbolinks:load', () => {
  $('#close-banner').click(function() {
    $('#banner').slideUp('slow');
  });

  $('#hide-tips').click(function(e) {
    e.preventDefault();
    $('#tips').fadeOut('slow');
  });

  // setTimeout(function() {
  //   $('#notice').slideUp('slow');
  // }, 5000);

  $('#notice-close').click(function(e) {
    $('#notice').slideUp('slow');
  });

  $('[data-toggle="tooltip"]').tooltip({
    offset: '0,3'
  });

  $('.users-select').select2({
    width: '100%',
    minimumInputLength: 2,
    ajax: {
      url: '', // orgs/ORG/settings/users.json
      dataType: 'json'
    }
  });

  // See internal PR for details
  var email = document.getElementById('contact-email');
  email.innerHTML = decode("pbagnpg@obygbcf.pbz");

});

function decode(a) {
  return a.replace(/[a-zA-Z]/g, function(c) {
    return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
  })
};
