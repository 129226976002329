document.addEventListener('turbolinks:load', () => {
  if (!$('.lessons-list')) {
    return
  }

  function addOtherLessons(e) {
    $('#add-other-lessons-wrap').toggle()
    $('.select2').css("width", "100%"); // fix the width
    e.preventDefault()
  }
  $('#add-other-lessons').on('click', addOtherLessons);
});
