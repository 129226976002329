import { Controller } from "stimulus"

require("jquery")

export default class extends Controller {
  static targets = [ "list", "caretup", "caretdown" ]

  connect() {
    var expand = $(this.listTarget).data('expand')
    if (expand) {
      this.toggle()
    }
  }

  toggle(e) {
    this.listTarget.classList.toggle("hideme");
    this.caretupTarget.classList.toggle("hideme");
    this.caretdownTarget.classList.toggle("hideme");
    if (e) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }
}
